import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/shared/Layout'
import SEO from '../components/shared/SEO'

class ArticlePage extends React.Component {

  render() {
    
    const {article} = this.props.data;
    if (!article) return null;

    return (
      <Layout>
        <SEO title={`${ article.frontmatter.title }`} />
        <Helmet title={`${ article.frontmatter.title }`} />        
      </Layout>
    )
  }
}

export default ArticlePage;

export const pageQuery = graphql`
  query($id: String!) {
    article: markdownRemark(id: { eq: $id }) {
      id
      timeToRead
      html
      frontmatter {
        title
        path
        date
      } 
    }
  }`